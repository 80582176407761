html {
  font-family: sans-serif;
  font-size: 1rem;
}

/* table {
  border: 1px solid rgba(0, 0, 0, 0.5);
  border-radius: 6px;
  background-color: rgba(0, 0, 0, 0.2);
  color: #ddd;
} */

/* th {
  border-bottom: 1px solid rgba(0, 0, 0, 0.5);
  border-right: 1px solid rgba(0, 0, 0, 0.5);
  padding: 2px 4px;
} */

.header-text {
  color: #fff;
  font-weight: 500;
  font-size: 1.1rem;
  cursor: pointer;
  text-align: left;
}

.header-filter {
  display: flex;
  align-content: safe start;
  margin-top: 4px;
}

/* tbody {
  border-bottom: 1px solid green;
} */

.row-border {
  border: 1px solid rgba(0, 0, 0, 0.5);
}

/* td {
  border: 1px solid rgba(0, 0, 0, 0.5);
} */

tfoot {
  color: gray;
}

tfoot th {
  font-weight: normal;
}

.component-container {
  width: 100%;
}

.table-container {
  /* width: 100%; */
  overflow-x: auto;
  max-height: 65vh;
  scrollbar-gutter: stable; /* For chromium browsers */
  &::-webkit-scrollbar {
    width: 5px;
  }
  /*&::-webkit-scrollbar-track {
    background: transparent;
  }
  &::-webkit-scrollbar-thumb {
    background: rgba(255, 255, 255, 0.4);
    border-radius: 5px;
  } */
}


table {
  border: 1px solid rgba(0, 0, 0, 0.5);
  background-color: rgba(0, 0, 0, 0.2);
  color: #e8e8e8;
  border-collapse: collapse;  /* Added to remove double borders */
  width: 100%;
}


th {
  border: 1px solid rgba(0, 0, 0, 0.5);
  padding: 8px 10px;
}

/* Add specific column widths */
/* th:first-child { */
  /* width: 40%; Email column gets more space */
/* } */

/* th:last-child {
  width: 1%; Delete column gets less space */
/* } */

/* th:not(:first-child):not(:last-child) {
  width: 10%; All other columns (checkboxes) get equal space */
/* } */

td {
  border: 1px solid rgba(0, 0, 0, 0.5);
  padding: 5px 10px;
  word-break: break-word;
}

tr:hover {
  background-color: rgba(255, 255, 255, 0.1);
}

.pagination-container {
  background-color: rgba(0, 0, 0, 0.2);
  border: solid rgba(0, 0, 0, 0.5);
  border-width: 0 1px 1px 1px;
  display: flex;
  flex-direction: row;
  justify-content: safe center;
  padding: 5px;
  color: #fff;
  gap: 10px;

  -webkit-margin-end: 5px; /* For chromium browsers */
}

.current-page-input {
  width: 3rem;
  outline: none;
  text-align: end;
}

.pagination-count {
  gap: 3px;
}

input[type="checkbox"] {
  appearance: none;
  width: 1.3rem;
  height: 1.3rem;
  border: 1px solid #d8d8d8;
  border-radius: 4px;
  background-color: rgba(0, 0, 0, 0.3);
  cursor: pointer;
  transition: background-color 0.5s, border-color 0.5s;
  display: block;
  font-size: var(--check-size, 1.3rem);
  font-weight: 500;
  color: #fff;
  line-height: 0.85;
  text-align: center;
}

input[type="checkbox"]:hover {
  border-color: #999;
}

input[type="checkbox"]:checked {
  background-color: #0f86a1;
  border-color: #000;
}

/* input[type="checkbox"]:checked::before {
  content: '\2713';
  
} */

input[type="checkbox"]:checked:hover {
  border-color: #444;
}

input[type="checkbox"]:disabled {
  background-color: #777;
  border-color: #444;
  cursor: not-allowed;
}

/* .search-input {
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 6px;
  padding: 2px 5px;
  outline: none;
  background-color: rgba(0, 0, 0, 0.3);
  box-sizing: border-box;
  color: #fff;
  box-shadow: inset 0px 0px 4px 1px rgba(0, 0, 0, 0.25);

  &::placeholder {
    color: #999;
    opacity: 1;
  }
} */

.table-input {
  background: transparent;
  border: none;
  outline: none;
  color: #fff;
  width: 100%;
}

.table-input:focus {
  background: transparent;
}

td:has(.table-input) {
  cursor: text;
}

td:has(.table-input):hover,
td:has(.table-input:hover) {
  box-shadow: inset 0px 0px 0px 1px rgba(0, 0, 0, 0.5);
}

td:has(.table-input:focus) {
  border-color: #000;
  box-shadow: inset 0px 0px 0px 1px rgba(0, 0, 0, 1);
}
